import styled from 'styled-components';
import { rem } from 'polished';
import { colors } from '../helpers/variables';

const LegalTextblock = styled.div`
    margin: 0 auto;
    line-height: 1.5em;
    text-align: left;
    font-size: ${rem(16)};

    h1 {
        margin-bottom: 0.75em;
        line-height: 1.3;
    }

    h2 {
        line-height: 1.3;
    }

    div {
        margin-bottom: 1.5em;
        line-height: 1.5em;
    }

    p {
        margin-bottom: 0.75em;
    }

    div,
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    a,
    a:hover,
    a:visited,
    a:active {
        cursor: pointer;
        text-decoration: none;
        color: ${colors.black};
        text-decoration: underline;
    }
`;

export default LegalTextblock;
